<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <v-row>
                            <!--                        <v-spacer />-->

                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="editedItem.name.value"
                                    class="purple-input"
                                    :label="$t('admin.classifiers.name')"
                                    :error-messages="editedItem.name.error"
                                    :rules="[rules.required]"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.name.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-text-field
                                    v-model="editedItem.description.value"
                                    class="purple-input"
                                    :label="$t('admin.classifiers.description')"
                                    :error-messages="editedItem.description.error"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.description.error = ''"
                                />
                            </v-col>

                        <!--                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-switch
                                v-model="editedItem.active.value"
                                inset
                                :label="$t('admin.cards.activate')"
                                :disabled="isOnlyView"
                            />
                        </v-col>-->
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />

                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t(`common.${ isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
                </v-btn>
                <v-btn
                    v-if="!isOnlyView"
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'

export default {
    components: { },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        },
        urlMain: {
            type: String,
            required: true
        },
        messagesId: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false
        }
    },
    computed: {
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t(`admin.classifiers.${this.messagesId}.view`)
                    : this.$t(`admin.classifiers.${this.messagesId}.edit`)
            } else return this.$t(`admin.classifiers.${this.messagesId}.new`)
        }
        // ...mapGetters(['role', 'cities'])
    },
    methods: {

        getEditedItem(itemInit = null) {
            const item = itemInit ?? this.item;

            var empty = !item

            return {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.name,
                    error: ''
                },
                description: {
                    value: empty ? '' : item.description,
                    error: ''
                }
            }
        },

        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("name", this.editedItem.name.value);
            formData.append("description", this.editedItem.description.value ?? '');

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            /* console.log('********** formData ********')
            console.log(formData) */

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                /* console.log('********** errors ********')
                console.log(error) */

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        },
        close() {
            this.$emit('update:dialog', false)
        }
    }
}
</script>

<style >

</style>
