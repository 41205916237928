import { mapGetters } from 'vuex'
import { debounce } from "debounce";
import axios from '@/plugins/axios'

export default {
    data: function () {
        return {
            dialog: false,
            search: '',
            items: [],
            itemSelected: null,
            sortby: ['id'],
            options: {
                itemsPerPage: 5
            }
        }
    },
    created: function () {
        this.fetchData();
    },
    mounted() {

    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },
    methods: {
        fetchData: debounce(function (e) {
            this.fetchDataFinal(e);
        }, 500),

        async fetchDataFinal(data = this.options) {
            try {
                const result = await axios
                    .get(`${this.urlMain}`);

                this.initialize(result.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 200) {
                    this.fetchData();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },

        filterTableItems(value, search, item) {
        // console.log("filterTableItems", value);
            let filter = false;
            if (value != null &&
          search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    if (value == search) {
                        filter = true;
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                }
            }

            return filter;
        },
        createItem() {
            this.dialog = true;
        // this.$router.push('/app/admin/games/classifiers/card_types/create');
        },
        editItem(item) {
            this.itemSelected = item;
            this.dialog = true;

        // this.$router.push({ name: 'CardTypeCreate', params: { item } })
        }
    },
    computed: {
    }
}
